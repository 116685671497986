<template>
    <v-stepper v-model="stepNo">
        <v-stepper-header>
            <template v-for="(step, index) in steps">
                <v-stepper-step :complete="stepNo > index+1" :step="index+1" :key="'step'+index">{{step}}</v-stepper-step>
                <v-divider v-if="index !== steps.length-1" :key="'divider'+index"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1">
                <v-layout justify-center fill-height>
                    <v-flex xs12 sm8 md4>
                        <v-card-text>
                            <v-form ref="form" v-model="valid">
                                <v-text-field outline clearable prepend-icon="kitchen" name="boxId" label="櫃體編號" type="tel"
                                              v-model="boxId" mask="######" :rules="boxIdRules" data-cy="boxIdField" required>
                                </v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn large block color="primary" :disabled="!valid" @click="getReopenInfo" data-cy="boxReopenSubmitBtn">送出</v-btn>
                        </v-card-actions>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="2">
                <v-layout>
                    <v-flex xs12 sm6 offset-sm3>
                        <v-card flat v-if="reopenInfo">
                            <v-list subheader two-line>
                                <v-subheader class="subheading">設定資料</v-subheader>
                                <template v-for="(item, index) in boxInfo" subheader>
                                    <v-list-tile :key="item.key">
                                        <v-list-tile-content>
                                            <v-list-tile-title> {{ item.key }} </v-list-tile-title>
                                            <v-list-tile-sub-title>{{ item.value }}</v-list-tile-sub-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider :key="'info' + index"></v-divider>
                                </template>
                            </v-list>
                            <v-card-actions>
                                <v-btn block large color="warning" @click="shutdown">關機</v-btn>
                                <v-btn block large color="primary" @click="reboot">重新開機</v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-btn flat block large color="primary" @click="setStepNo(1)">
                            <v-icon small>arrow_back_ios</v-icon>重設櫃號&nbsp;{{boxId}}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="3">
                <v-layout>
                    <v-flex xs12 sm6 md4>
                        <div v-if="isReopen" class="text-xs-center">
                            <v-card flat v-if="reopenInfo">
                                <v-list subheader two-line>
                                    <p class="bold">重開成功</p>
                                    <v-list-tile>
                                        <v-list-tile-content>
                                            <v-list-tile-title> {{ boxInfo[0].key }} </v-list-tile-title>
                                            <v-list-tile-sub-title>{{ boxInfo[0].value  }}</v-list-tile-sub-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider></v-divider>
                                    <v-list-tile>
                                        <v-list-tile-content>
                                            <v-list-tile-title> {{ boxInfo[1].key }} </v-list-tile-title>
                                            <v-list-tile-sub-title>{{ boxInfo[1].value  }}</v-list-tile-sub-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider></v-divider>
                                </v-list>
                            </v-card>
                        </div>
                        <div v-else class="text-xs-center">
                            <v-card flat v-if="reopenInfo">
                                <v-list subheader two-line>
                                    <p class="bold">關機成功</p>
                                    <v-list-tile>
                                        <v-list-tile-content>
                                            <v-list-tile-title> {{ boxInfo[0].key }} </v-list-tile-title>
                                            <v-list-tile-sub-title>{{ boxInfo[0].value  }}</v-list-tile-sub-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider></v-divider>
                                    <v-list-tile>
                                        <v-list-tile-content>
                                            <v-list-tile-title> {{ boxInfo[1].key }} </v-list-tile-title>
                                            <v-list-tile-sub-title>{{ boxInfo[1].value  }}</v-list-tile-sub-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider></v-divider>
                                </v-list>
                            </v-card>
                            <v-alert :value="true" type="warning" class="subheading">{{ shutdownMsg() }}<br/>請檢查主機是否已關機<br/>(螢幕呈現 *藍屏*，且樹莓派綠燈已熄滅)</v-alert>
                        </div>
                    </v-flex>
                </v-layout>
                <v-layout justify-center>
                    <v-btn flat block large color="primary" @click="setStepNo(1)">
                        <v-icon small>arrow_back_ios</v-icon>重設櫃號
                    </v-btn>
                    <v-btn large block class="ma-1" color="primary" @click="finish" data-cy="finishBtn">完成</v-btn>
                </v-layout>
            </v-stepper-content>
        </v-stepper-items>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
    </v-stepper>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import { HostUrl, SideBtnType } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import i18n from '@/i18n'
import MQTTMessageWatcher from '@/mixins/MQTTMessageWatcher'
import { publish } from '@/utils/MQTTClient'

const SHUTDOWN_BOX = 0
const REBOOT_BOX = 1

export default {
    name: 'BoxReopen',
    components: { WaitingDialog },
    mixins: [ WaitDialogControl, MQTTMessageWatcher ],
    data() {
        return {
            steps: ['輸入櫃號', '電源設定', '設定完成'],
            stepNo: 1,
            valid: false,
            boxId: '',
            boxIdRules: [
                v => !!v || '請輸入櫃體編號',
                v => /\d{6}/.test(v) || '請輸入有效櫃體編號'
            ],
            reopenInfo: null,
            isReopen: false,
            warningMsg: null,
            waitingDialogCloseFunc: () => {},
            timeout: null,
            watchers: []
        }
    },
    computed: {
        boxInfo() {
            if (!this.reopenInfo) return null
            return [
                { key: '編號', value: this.reopenInfo.box_id },
                { key: '名稱', value: this.reopenInfo.name },
                { key: '地址', value: this.reopenInfo.country + this.reopenInfo.city + this.reopenInfo.address },
                { key: '位置', value: this.reopenInfo.location },
                { key: '最後回報時間', value: this.reopenInfo.report_time || '(未回報)' },
                { key: '前次重開機時間', value: this.reopenInfo.reboot_time || '(未回報)' }
            ]
        }
    },
    methods: {
        init() {
            this.stepNo = 1
            this.valid = false
            this.boxId = ''
            this.reopenInfo = null
            this.isReopen = false
            this.warningMsg = null
            this.waitingDialogCloseFunc = () => {}
        },
        async getReopenInfo() {
            if (this.$refs.form.validate()) {
                store.commit('setTargetBoxId', this.boxId)
                this.showDialog('取得重開資訊...', '取得重開資訊失敗')
                try {
                    let subUrl = '/api/box/' + this.boxId + '/setting'
                    let response = await axios.get(HostUrl + subUrl, getAxiosConfig())
                    if (response.data.code === 0) {
                        this.reopenInfo = response.data.setting
                        this.setStepNo(2)
                    } else {
                        store.commit('setSnackBar', {
                            message: response.data.message,
                            color: 'error'
                        })
                    }
                } catch(error) {
                    store.commit('setSnackBar', {
                        message: '伺服器錯誤，請稍後再試',
                        color: 'error'
                    })
                } finally {
                    this.closeDialog()
                }
            } else {
                store.commit('setSnackBar', {
                    message: '輸入資料錯誤, 請修改紅色欄位內容',
                    color: 'error'
                })
            }
        },
        setStepNo(no) {
            if (no === 1) this.init()
            this.stepNo = no
            store.commit('setTitle', this.steps[this.stepNo - 1])
            this.closeDialog()
        },
        shutdownMsg() {
            var t = new Date()
            var stamp = t.setTime(t.getTime() + 1000 * 80)
            var date = new Date(stamp)
            var timestring = date.getHours() + ':' + date.getMinutes()

            return '預計 ' + timestring + '關機 ...'
        },
        async shutdown() {
            this.showDialog('傳送關機指令中(請耐心等候)', '關機失敗', 70000)
            try {
                let subUrl = '/api/box/' + this.boxId + '/boot'
                let response = await axios.post(HostUrl + subUrl, {
                    client_id: store.getters.clientId,
                    status: SHUTDOWN_BOX
                }, getAxiosConfig())
                if (response.data.code === 0) {
                    setTimeout(() => {
                        this.setStepNo(3)
                        this.closeDialog()
                    }, 40000)
                } else {
                    store.commit('setSnackBar', {
                        message: response.data.message,
                        color: 'error'
                    })
                    this.closeDialog()
                }
            } catch(error) {
                store.commit('setSnackBar', {
                    message: '伺服器錯誤，請稍後再試',
                    color: 'error'
                })
                this.closeDialog()
            } finally {
                console.log('finally')
            }
        },
        reboot() {
            this.showDialog('重新開機中(最多等待5分鐘)', '重新開機失敗', 300000)
            let subUrl = '/api/box/' + this.boxId + '/boot'
            let payload = {
                client_id: store.getters.clientId,
                status: REBOOT_BOX
            }
            axios.post(HostUrl + subUrl, payload, getAxiosConfig())
                .then((response) => {
                    if (response.data.code === 0) {
                        this.isReopen = true
                    } else {
                        store.commit('setSnackBar', {
                            message: response.data.message,
                            color: 'error'
                        })
                        this.closeDialog()
                    }
                })
                .catch((error) => {
                    console.log('catch error: ', error)
                    store.commit('setSnackBar', {
                        message: '伺服器錯誤，請稍後再試'
                    })
                    this.closeDialog()
                })
        },
        finish() {
            this.$router.push(`/${i18n.locale}/staff/menu`);
            this.init()
        },
        handleMQTTMessage(message) {
            console.log('message', message)
            if (message === null) console.log('[reboot] MQTT message is null')
            else if (message.topic.endsWith('/box/reboot')) this.handleRebootReport(message)
        },
        handleStatusReport(message) {
            console.log('handleStatusReport', message)
            clearTimeout(this.timeout)
            this.closeDialog()
            this.setStepNo(3)
        },
        handleRebootReport(message) {
            console.log('handleRebootReport: ', message)
            this.getBoxStatus()
        },
        getBoxStatus() {
            let topic = 'box/' + this.boxId + '/management/status'
            publish(topic, {
                user_id: store.getters.user.id,
                client_id: store.getters.clientId
            })
            this.timeout = setTimeout(this.getBoxStatus, 10000)
        },
        watchBoxStatus() {
            this.watchers.push(store.watch(
                (state, getters) => getters.boxStatusResult,
                (response) => {
                    if (response) {
                        this.handleStatusReport(response)
                    }
                    store.commit('setBoxStatusResult', null)
                }
            ))
        }
    },
    activated() {
        store.commit('setTitle', '輸入櫃號')
        store.commit('setSideBtnType', SideBtnType.Back)
        this.init()
        this.watchBoxStatus()
    },
    deactivated() {
        store.commit('setSideBtnType', SideBtnType.Navi)
        if (this.watchers.length > 0) {
            this.watchers.forEach(w => w())
            this.watchers = []
        }
        clearTimeout(this.timeout)
    }
}
</script>

<style scoped>
.bold {
    font-weight: bold;
    font-size: 1.5rem;
}
</style>